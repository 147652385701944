import React, { useEffect, useState } from 'react';
import Title from 'antd/lib/typography/Title';
import Link from 'next/link';

const Home = () => {
  return (
    <div>
      <Title level={2}>Welcome</Title>

      <p>
        You've successfully logged into the Axle freight brokerage account!
        Congrats.
      </p>
      <br></br>
      <p>
        Checkout the <Link href='/loadboard'>Loadboard</Link> and{' '}
        <Link href='/account'>Account</Link> pages to continue.
      </p>
    </div>
  );
};

export default Home;
